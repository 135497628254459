import React from "react";
import { body } from "../../../commonStyles/Fonts";
import { styled } from "../../../../stitches.config";
import Link from "../../../../components/Link";

export interface DropdownMobileProps {
    setIsMobileMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
    links: Array<{
        url?: string;
        text?: string;
    }>;
}

const DropdownMobile: React.FC<DropdownMobileProps> = ({
    setIsMobileMenuActive,
    links
}) => {

    const handleNavigation = () => {
        setIsMobileMenuActive(false);
        window.scrollTo(0, 0);
    };

    return (
        <Container className="dropdown-menu-mobile">
            <Wrap onClick={handleNavigation} className={body()}>
                {links?.map((link, id) => (
                    <Link key={id} to={link.url}>{link.text}</Link>
                ))}
            </Wrap>
        </Container>

    );
};

const Container = styled("div", {
    overflow: "hidden",
    width: "100%",
})

const Wrap = styled("div", {
    color: "$grayscale0Fixed",
    padding: "0px",
    position: "relative",
    width: "100%",

    "& a": {
        marginTop: "16px",
        display: "block",
    }
})


export default DropdownMobile;