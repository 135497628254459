import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "../../assets/icons/close-menu-icon.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-menu-mobile.svg";
import { ReactComponent as SubscribedIcon } from "../../assets/icons/star-subscribed-nav-mb.svg";
import { ReactComponent as UnsubscribedIcon } from "../../assets/icons/star-unsubscribed-nav-mb.svg";
import { styled } from "../../../stitches.config";

import { Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { useStores } from "../../../helpers";

import DropdownMobile from "./DropdownMenu/DropdownMobile"

import { DISABLE_PREMIUM_FLAG_ACTIVE } from "../../../components/common/constants/constants"

const CATEGORIES = window.APP_SETTINGS.nav_categories;

interface Props {
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavMenuMobile: React.FC<Props> = ({
  isMobileMenuActive,
  setIsMobileMenuActive,
}) => {
  const [{ user }, dispatch] = useAuth();
  const { UIStore } = useStores();

  const handleNavigation = () => {
    setIsMobileMenuActive(false);
    window.scrollTo(0, 0);
  };

  const onToggleFollow = (id: number) => {
    const followedIds = Object.values(user?.settings.followed_categories ?? {});

    const addOrRemove = (array: number[], value: number) => {
      var index = array.indexOf(value);

      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
      return array.map((str) => +str);
    };

    dispatch({
      type: "update_user",
      // @ts-ignore
      payload: {
        ...user,
        settings: {
          ...user!.settings,
          followed_categories: addOrRemove(followedIds, id),
        },
      },
    });
  };

  return (
    <NavMenuMobileWrapper active={isMobileMenuActive}>
      <Header>Kategorie</Header>
      <CloseIcon
        className="closeIcon"
        onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
      />
      <MenuItemsList>
        {CATEGORIES.map((el, ix) => (
          <MenuItem>
            <Link
              to={el.url}
              key={`mobileMenuItem${ix}`}
              onClick={handleNavigation}
            >
              <FlexBetween>
                <div>
                  {el.name}
                  <ChevronIcon className="chevronIcon" />
                </div>
                {el?.id && !DISABLE_PREMIUM_FLAG_ACTIVE && (
                  <div style={{ pointerEvents: "none" }}>
                    <SubscribeBtn
                      type="button"
                      className={
                        user?.settings.followed_categories.includes(Number(el.id))
                          ? "subscribed"
                          : ""
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (!user || !user.valid) {
                          UIStore.setShowLoginModal(true);
                        } else {
                          onToggleFollow(Number(el.id));
                        }
                      }}
                    >
                      {user?.settings.followed_categories.includes(
                        Number(el.id)
                      ) ? (
                        <>
                          Obserwujesz
                          <SubscribedIcon className="subscribeIcon" />
                        </>
                      ) : (
                        <>
                          Obserwuj
                          <UnsubscribedIcon className="subscribeIcon" />
                        </>
                      )}
                    </SubscribeBtn>
                  </div>
                )}
              </FlexBetween>
            </Link>
            {isMobileMenuActive && el.links &&
              <DropdownMobile setIsMobileMenuActive={setIsMobileMenuActive} links={el.links} />
            }
          </MenuItem>
        ))}
      </MenuItemsList>
    </NavMenuMobileWrapper>
  );
};

const NavMenuMobileWrapper = styled("div", {
  backgroundColor: "#131212",
  height: "100vh",
  minHeight: "100%",
  minWidth: "100%",
  position: "fixed",
  top: "0",
  left: "-100%",
  transition: "850ms",
  zIndex: "999999",
  color: "#FFFFFF",
  padding: "56px 16px 0",

  "@bp4": {
    display: "none",
  },

  variants: {
    active: {
      true: {
        left: "0",
        transition: "350ms",
      },
    },
  },

  ".closeIcon": {
    position: "absolute",
    top: "24px",
    right: "16px",
  },

  ".chevronIcon": {
    marginLeft: "4px",
  },

  ".subscribeIcon": {
    position: "absolute",
    right: "12px",
    top: "50%",
    transform: "translateY(-50%)",
  },
});

const Header = styled("h3", {
  fontWeight: "700",
  fontSize: "38px",
  lineHeight: "42px",
  margin: "0 0 32px",
});

const MenuItemsList = styled("ul", {
  padding: "0",
  height: "86%",
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  "overflow-y": "scroll",

  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const MenuItem = styled("li", {
  listStyle: "none",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "24px",
  letterSpacing: "0.2px",
  padding: "20px 0",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  a: {
    width: "100%",
  },

  "&:last-of-type": {
    borderBottom: "none",
  },
});

const SubscribeBtn = styled("button", {
  padding: "8px 30px 6px 12px",
  backgroundColor: "transparent",
  border: "1px solid #3A3A3A",
  position: "relative",
  zIndex: 10,
  pointerEvents: "auto",

  "&.subscribed": {
    backgroundColor: "var(--colors-primary)",
  },
});

const FlexBetween = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export default NavMenuMobile;
